export const resetStateInMenuDropdown = (ref: any, callback: () => void) => {
  const getClassName = ref?.current?.className

  if (getClassName) {
    if (getClassName.includes('show')) {
      callback()
    }
  }
}

export const classNames = (arr: any) => {
  return [...arr].filter(Boolean).join(' ')
}

export const shortenFileNameByWidth = (fileName: string, maxWidth: number): string => {
  const hiddenElement = document.createElement('span')
  hiddenElement.style.position = 'absolute'
  hiddenElement.style.whiteSpace = 'nowrap'
  hiddenElement.style.visibility = 'hidden'
  document.body.appendChild(hiddenElement)

  hiddenElement.textContent = fileName

  if (hiddenElement.offsetWidth <= maxWidth) {
    // File name fits within the width, return it unchanged
    document.body.removeChild(hiddenElement)
    return fileName
  }

  const extensionIndex = fileName.lastIndexOf('.')
  const nameWithoutExtension = fileName.slice(0, extensionIndex)
  const extension = fileName.slice(extensionIndex)

  for (let i = nameWithoutExtension.length; i > 0; i--) {
    hiddenElement.textContent = `${nameWithoutExtension.slice(0, i)}...${extension}`
    if (hiddenElement.offsetWidth <= maxWidth) {
      document.body.removeChild(hiddenElement)
      return `${nameWithoutExtension.slice(0, i)}...${extension}`
    }
  }

  document.body.removeChild(hiddenElement)
  return fileName
}
