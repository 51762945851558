import 'moment/locale/vi'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/vi'
import '@formatjs/intl-relativetimeformat/polyfill'

import moment from 'moment'
import {FC} from 'react'
import {IntlProvider} from 'react-intl'

import {WithChildren} from '../helpers'
import {en, vi} from './messages/i18n'
import {useLang} from './Metronici18n'

const flattenObject = (ob: any) => {
  const toReturn: any = {}

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i])
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

const allMessages = {
  vi,
  en,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()

  const messages = allMessages[locale]
  const newMessages = flattenObject(messages)
  moment.locale(locale)

  return (
    <IntlProvider locale={locale} messages={newMessages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
