import {
  AuthModel,
  ChangeUserPasswordType,
  ILoginForm,
  IUserProfileForm,
  ResetPasswordType,
  UserProfileChangePasswordType,
  UserProfileModel,
} from '@/types/auth'
import axiosInstance from '@/utils/axios'
import {ResponseApi} from '@/utils/typeResponseApi'

export function loginService({email, password}: ILoginForm) {
  return axiosInstance.post<AuthModel>('/api/v1/auth/admin-login', {
    email,
    password,
  })
}

export function logoutService() {
  return axiosInstance.get<ResponseApi<{}>>('/api/v1/auth/logout')
}

export function getUserProfile() {
  return axiosInstance.get<UserProfileModel>('/api/v1/auth/me')
}

export function settingsProfileService(data: IUserProfileForm) {
  return axiosInstance.post<UserProfileModel>('/api/v1/auth/settings', data)
}

export function changePasswordService(data: UserProfileChangePasswordType) {
  return axiosInstance.post<UserProfileModel>('/api/v1/auth/change-password', data)
}

export function sendEmailOTPService(data: {email: string}) {
  return axiosInstance.post('/api/v1/auth/send-email-otp', data)
}

export function verifyOTPService(data: {email: string}) {
  return axiosInstance.post('/api/v1/auth/verify-forgot-otp', data)
}

export function resetPasswordService(data: ResetPasswordType) {
  return axiosInstance.post('/api/v1/auth/reset-password', data)
}

export function changePasswordByUserIdService(id: string, data: ChangeUserPasswordType) {
  return axiosInstance.put<any>(`/api/v1/users/change-password/${id}`, data)
}
