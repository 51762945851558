export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string, secondUrl?: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url || (secondUrl && current === secondUrl)) {
    return true
  }

  if (current.indexOf(url) > -1 || (secondUrl && current.indexOf(secondUrl as string) > -1)) {
    return true
  }

  return false
}
