import axios, {AxiosInstance, InternalAxiosRequestConfig} from 'axios'

import {IAuth} from '@/types/auth'

const AUTH_LOCAL_STORAGE_KEY = 'accessToken'
export const getAuthFromLocalStorage = (): IAuth | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: IAuth = JSON.parse(lsValue) as IAuth
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    try {
      const autLocalStorage = getAuthFromLocalStorage()

      if (autLocalStorage && autLocalStorage.access_token) {
        config.headers.setAuthorization(`Bearer ${autLocalStorage.access_token}`)
      }
      return config
    } catch (error: any) {
      throw new Error(error)
    }
  },
  (error: any) => Promise.reject(error)
)

export default axiosInstance
