import {useMemo, useState} from 'react'
import {Button, Form, InputGroup, Tab, Tabs} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {resetPasswordService, sendEmailOTPService, verifyOTPService} from '@/services/auth'
import {ResetPasswordType} from '@/types/auth'
import {ForgotPasswordResolver, ResetPasswordResolver, VerifyOTPResolver} from '@/validations/loginValidation'

export function ForgotPassword() {
  const intl = useIntl()
  const [loadingSendEmail, setLoadingSendEmail] = useState(false)
  const [loadingSendOtp, setLoadingSendOtp] = useState(false)
  const [loadingResetPassword, setLoadingResetPassword] = useState(false)
  const [showNewPass, setShowNewPass] = useState<boolean>(false)
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false)

  const [activeTab, setActiveTab] = useState<string>('1')
  const [sendEmailStatus, setSendEmailStatus] = useState<string | null>(null)
  const [verifyOTPStatus, setVerifyOTPStatus] = useState<string>('')

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    formState: {errors, isValid, isSubmitting, isDirty},
  } = useForm<{email: string}>({
    resolver: ForgotPasswordResolver,
    defaultValues: {
      email: '',
    },
  })

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    watch: watch1,
    formState: {errors: errors1, isValid: isValid1, isSubmitting: isSubmitting1, isDirty: isDirty1},
  } = useForm<{
    email: string
    otp: string
  }>({
    resolver: VerifyOTPResolver,
    values: useMemo(
      () => ({
        email: getValues('email'),
        otp: '',
      }),
      [watch('email')]
    ),
  })

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    reset: reset2,
    setError,
    formState: {errors: errors2, isValid: isValid2, isSubmitting: isSubmitting2, isDirty: isDirty2},
  } = useForm<{
    newPassword: string
    confirmPassword: string
  }>({
    resolver: ResetPasswordResolver,
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (data: {email: string}) => {
    setLoadingSendEmail(true)
    await sendEmailOTPService(data)
      .then((res) => {
        if (res.status === 201) {
          setSendEmailStatus('success')
          setActiveTab('2')
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 410) {
          setSendEmailStatus(err?.response?.data?.message)
        } else {
          toast.error(err?.response?.data?.message)
        }
      })
    setLoadingSendEmail(false)
  }

  const onSubmit1 = async (data: {email: string; otp: string}) => {
    setLoadingSendOtp(true)
    await verifyOTPService(data)
      .then((res) => {
        if (res.status === 201) {
          setVerifyOTPStatus('success')
          setActiveTab('3')
        }
      })
      .catch((err: any) => {
        console.log('verify OTP failed: ', err)
        if (err?.response?.status === 400) {
          console.log('Invalid OTP')
          setVerifyOTPStatus(err?.response?.data?.message)
        }
      })
    setSendEmailStatus(null)
    setLoadingSendOtp(false)
  }

  const onSubmit2 = async (data: {newPassword: string; confirmPassword: string}) => {
    if (!watch1('otp')) {
      toast.error('Something was wrong. Please resend an email OTP again')
      return
    }

    const payload: ResetPasswordType = {
      newPassword: data.newPassword,
      email: watch1('email'),
      otp: watch1('otp'),
    }

    setLoadingResetPassword(true)
    await resetPasswordService(payload)
      .then((res) => {
        if (res.status === 201) {
          toast.success('Reset password successfully !')
          setActiveTab('4')
          setSendEmailStatus(null)
          setVerifyOTPStatus('')
        }
      })
      .catch((err: any) => {
        console.log('Reset password failed: ', err)

        setError(err?.response?.data?.errors[0]?.field, {
          message: err?.response?.data?.errors[0]?.message,
        })
      })
    setLoadingResetPassword(false)
  }

  const FormEnterEmail = () => (
    <Form noValidate id='kt_login_password_reset_form' onSubmit={handleSubmit(onSubmit)}>
      {/* end::Title */}

      {/* begin::Form group */}
      <Form.Group className='fv-row mb-8'>
        <Form.Label className='fs-4 fw-bolder text-dark'>Email</Form.Label>
        <Form.Control
          type='email'
          disabled={loadingSendEmail}
          {...register('email')}
          className={['form-control bg-transparent', errors.email && 'is-invalid', isSubmitting && isValid && 'is-valid']
            .filter(Boolean)
            .join(' ')}
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'AUTH.ENTER_EMAIL'})}
        />
        {errors.email && <Form.Text className='text-danger'>{errors.email.message}</Form.Text>}
      </Form.Group>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={isSubmitting || !isDirty}>
          {loadingSendEmail ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
        <Link to='/auth/login'>
          <button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-light' disabled={isSubmitting}>
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </Form>
  )

  const FormEnterOTP = () => (
    <Form noValidate id='kt_login_password_reset_form' onSubmit={handleSubmit1(onSubmit1)}>
      {/* end::Title */}

      {/* begin::Form group */}
      <Form.Group className='fv-row mb-8'>
        <Form.Label className='fs-4 fw-bolder text-dark'>OTP</Form.Label>
        <Form.Control
          type='text'
          {...register1('otp')}
          disabled={loadingSendOtp}
          className={['form-control bg-transparent', errors1.otp && 'is-invalid', isSubmitting1 && isValid1 && 'is-valid']
            .filter(Boolean)
            .join(' ')}
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'AUTH.ENTER_OTP'})}
        />
        {errors1.otp && <Form.Text className='text-danger'>{errors1.otp.message}</Form.Text>}
      </Form.Group>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={isSubmitting1 || !isDirty1}>
          {loadingSendOtp ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
        <Button type='button' id='back_to_form_email_button' variant='light' disabled={isSubmitting1} onClick={() => setActiveTab('1')}>
          Back
        </Button>{' '}
      </div>
      {/* end::Form group */}
    </Form>
  )

  const FormResetPassword = () => (
    <Form noValidate id='kt_login_password_reset_form' onSubmit={handleSubmit2(onSubmit2)}>
      {/* end::Title */}

      {/* begin::Form group */}
      <Form.Group className='mb-3' controlId='newPassword'>
        <Form.Label className='fw-semibold required'>{intl.formatMessage({id: 'AUTH.NEW_PASSWORD'})}</Form.Label>
        <InputGroup>
          <Form.Control
            className='h-46 border-end-0'
            disabled={loadingResetPassword}
            type={showNewPass ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'AUTH.ENTER_NEW_PASSWORD'})}
            {...register2('newPassword')}
            aria-invalid={errors2.newPassword ? 'true' : 'false'}
          />
          <InputGroup.Text className='sufix-icon cursor-pointer' onClick={() => setShowNewPass(!showNewPass)}>
            {showNewPass ? (
              <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-5' />
            ) : (
              <KTSVG path='/media/icons/duotune/general/gen073.svg' className='svg-icon-5' />
            )}
          </InputGroup.Text>
        </InputGroup>
        {errors2.newPassword && <Form.Text className='text-danger'>{errors2.newPassword.message}</Form.Text>}
      </Form.Group>
      <Form.Group className='mb-3' controlId='confirmPassword'>
        <Form.Label className='fw-semibold required'>{intl.formatMessage({id: 'AUTH.CONFIRM_PASSWORD'})}</Form.Label>
        <InputGroup>
          <Form.Control
            className='h-46 border-end-0'
            disabled={loadingResetPassword}
            type={showConfirmPass ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'AUTH.ENTER_CONFIRM_PASSWORD'})}
            {...register2('confirmPassword')}
            aria-invalid={errors2.confirmPassword ? 'true' : 'false'}
          />
          <InputGroup.Text className='sufix-icon cursor-pointer' onClick={() => setShowConfirmPass(!showConfirmPass)}>
            {showConfirmPass ? (
              <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-5' />
            ) : (
              <KTSVG path='/media/icons/duotune/general/gen073.svg' className='svg-icon-5' />
            )}
          </InputGroup.Text>
        </InputGroup>
        {errors2.confirmPassword && <Form.Text className='text-danger'>{errors2.confirmPassword.message}</Form.Text>}
      </Form.Group>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={isSubmitting1 || !isDirty1}>
          {loadingResetPassword ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
        <Button type='button' id='back_to_form_email_button' variant='light' disabled={isSubmitting1} onClick={() => setActiveTab('1')}>
          Back
        </Button>{' '}
      </div>
      {/* end::Form group */}
    </Form>
  )

  const Finally = () => (
    <div>
      <h6 className='text-center'>Reset password successfully</h6>
      <Link to='/auth/login'>
        <Button type='button' id='back_to_login_button' className='mt-4 m-auto d-block'>
          Back to login
        </Button>
      </Link>{' '}
      <img
        className='mx-auto w-275px mb-10 mb-lg-20 d-block m-auto mt-10'
        src={toAbsoluteUrl('/media/auth/ok.svg')}
        alt='reset-password-success'
      />
    </div>
  )

  return (
    <div id='forgot-password-page' className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      {activeTab !== '4' && (
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>
      )}

      {/* begin::Title */}

      {sendEmailStatus === 'success' && (
        <div className='mb-10 bg-light-success p-3 rounded'>
          <div className='text-success fw-bold fs-14px text-center'>Sent OPT code successfully. Please check your email !</div>
        </div>
      )}
      {sendEmailStatus && sendEmailStatus !== 'success' && (
        <div className='mb-10 bg-light-warning p-3 rounded'>
          <div className='text-warning fw-bold fs-14px text-center'>{sendEmailStatus}</div>
        </div>
      )}

      {verifyOTPStatus === 'success' && (
        <div className='mb-10 bg-light-success p-3 rounded'>
          <div className='text-success fw-bold fs-14px text-center'>Verified OPT code successfully. Please reset your new password !</div>
        </div>
      )}
      {verifyOTPStatus === 'Invalid OTP' && (
        <div className='mb-10 bg-light-danger p-3 rounded'>
          <div className='text-danger fw-bold fs-14px text-center'>{verifyOTPStatus}</div>
        </div>
      )}

      <Tabs activeKey={activeTab} id='forgot-password-tabs' className='mb-3'>
        <Tab eventKey='1' title=''>
          {FormEnterEmail()}
        </Tab>
        <Tab eventKey='2' title=''>
          {FormEnterOTP()}
        </Tab>
        <Tab eventKey='3' title=''>
          {FormResetPassword()}
        </Tab>
        <Tab eventKey='4' title=''>
          {Finally()}
        </Tab>
      </Tabs>
    </div>
  )
}
