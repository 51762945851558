import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '@/_metronic/assets/ts/_utils'
import {WithChildren} from '@/_metronic/helpers'
import {MasterLayout} from '@/_metronic/layout/MasterLayout'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/users-management/UsersPage'))
  const MyProfilePage = lazy(() => import('../modules/my-profile/ProfilePage'))
  const ContactFeedbacksPage = lazy(() => import('../modules/contact-feedback/ContactFeedbacksPage'))
  const ProductsPage = lazy(() => import('../modules/products-management/ProductsPage'))
  const TransactionsPage = lazy(() => import('../modules/transactions-management/TransactionsPage'))
  const PaymentChannelsPage = lazy(() => import('../modules/payment-channels-management/PaymentChannelPage'))
  const MenuPage = lazy(() => import('../modules/menu-management/MenuPage'))
  const BannersPage = lazy(() => import('../modules/banner-management/BannersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/users-management' />} />
        {/* Pages */}
        {/* Lazy Modules */}
        <Route
          path='users-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='my-profile/*'
          element={
            <SuspensedView>
              <MyProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='contact-feedback/*'
          element={
            <SuspensedView>
              <ContactFeedbacksPage />
            </SuspensedView>
          }
        />

        <Route
          path='products-management/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />

        <Route
          path='transactions-management/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='payment-channels/*'
          element={
            <SuspensedView>
              <PaymentChannelsPage />
            </SuspensedView>
          }
        />

        <Route
          path='menu-management/*'
          element={
            <SuspensedView>
              <MenuPage />
            </SuspensedView>
          }
        />

        <Route
          path='banner-management/*'
          element={
            <SuspensedView>
              <BannersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' replace />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
