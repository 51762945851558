/* eslint-disable quotes */
import {useIntl} from 'react-intl'

import {MegaMenu} from './MegaMenu'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuInnerWithSub title='Apps' to='/pages' menuPlacement='bottom-start' menuTrigger='click'>
        {/* USERS */}
        <MenuInnerWithSub
          title={intl.formatMessage({id: 'USER.USERS'})}
          to='/users-management'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <MenuItem to='/users-management/users' title={intl.formatMessage({id: 'LIST'})} hasBullet={true} />
        </MenuInnerWithSub>

        {/* CONTENT MANAGEMENT */}
        {/* <MenuInnerWithSub
          title={intl.formatMessage({id: 'CONTENT.CONTENTS_MANAGEMENT'})}
          to='/contents-management'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <MenuItem
            to='/contents-management/contents'
            title={intl.formatMessage({id: 'LIST'})}
            hasBullet={true}
          />
        </MenuInnerWithSub> */}

        {/* FEEDBACK & CONTACT */}
        <MenuInnerWithSub
          title={intl.formatMessage({id: 'CONTACT_FEEDBACK.TITLE'})}
          to='/contact-feedback'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <MenuItem to='/contact-feedback/list' title={intl.formatMessage({id: 'LIST'})} hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
