import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'

import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {useLang} from '@/_metronic/i18n/Metronici18n'
import {HeaderNotificationsMenu, HeaderUserMenu, Search} from '@/_metronic/partials'
import {useAuth} from '@/app/modules/auth'
import {classNames} from '@/utils/utils'

import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'svg-icon-5 text-primary'

const Navbar = () => {
  const intl = useIntl()
  const lang = useLang()
  const {config} = useLayout()
  const {currentUser} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
          </div>
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div id='kt_activities_toggle' className={btnClass}>
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
              <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
            </div>
          </div>
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div className={btnClass}>
            <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
              <KTSVG path='/media/icons/duotune/ecommerce/ecm012.svg' className={btnIconClass} />
            </div>
          </div>
        </div>

        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={btnClass}>
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen025.svg' className={btnIconClass} />
          </div>
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        {lang === 'en' && (
          <OverlayTrigger
            key='flag-en'
            placement='bottom-start'
            overlay={<Tooltip id='tooltip-navbar-search'>{intl.formatMessage({id: 'LANGUAGE.ENGLISH'})}</Tooltip>}
          >
            <div className={btnClass}>
              <KTSVG path='/media/flags/united-states.svg' className={btnIconClass} />
            </div>
          </OverlayTrigger>
        )}
        {lang === 'vi' && (
          <OverlayTrigger
            key='flag-vi'
            placement='bottom-start'
            overlay={<Tooltip id='tooltip-navbar-search'>{intl.formatMessage({id: 'LANGUAGE.VIETNAM'})}</Tooltip>}
          >
            <div className={btnClass}>
              <KTSVG path='/media/flags/vietnam.svg' className={btnIconClass} />
            </div>
          </OverlayTrigger>
        )}
      </div>

      <div className={classNames(['app-navbar-item', itemClass])}>
        <span className='fs-11px'>{intl.formatMessage({id: 'NAVBAR.HI'})},</span>{' '}
        <span className='fs-3 fw-bold'>{currentUser?.username}</span>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={currentUser?.avatar ? currentUser?.avatar : toAbsoluteUrl('/media/avatars/blank.png')}
            alt='Avatar profile'
            className='rounded'
          />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div className='btn btn-icon btn-active-color-primary w-35px h-35px' id='kt_app_header_menu_toggle'>
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
