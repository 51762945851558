import {FC} from 'react'
import {useIntl} from 'react-intl'

import {toAbsoluteUrl} from '../../../helpers'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'LANGUAGE.ENGLISH',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'vi',
    name: 'LANGUAGE.VIETNAM',
    flag: toAbsoluteUrl('/media/flags/vietnam.svg'),
  },
]

const Languages: FC = () => {
  const intl = useIntl()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <div className='menu-item px-5' data-kt-menu-trigger='hover' data-kt-menu-placement='left-start' data-kt-menu-flip='bottom'>
      <div className='menu-link ps-5 pe-0'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'LANGUAGE.TITLE'})}
          <span className='rounded bg-primary-8 px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {intl.formatMessage({id: currentLanguage?.name})}
            <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='metronic' />
          </span>
        </span>
      </div>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <div className={['menu-link d-flex px-5', l.lang === currentLanguage?.lang && 'active'].filter(Boolean).join(' ')}>
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {intl.formatMessage({id: l.name})}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
