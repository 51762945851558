import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState} from 'react'

import {WithChildren} from '@/_metronic/helpers'
import {LayoutSplashScreen} from '@/_metronic/layout/core'
import {getUserProfile, logoutService} from '@/services/auth'
import {IAuth, IUserProfile} from '@/types/auth'
import {getAuthFromLocalStorage} from '@/utils/axios'

import * as authHelper from './AuthHelpers'

type AuthContextProps = {
  auth: IAuth | undefined
  saveAuth: (auth: IAuth | undefined) => void
  currentUser: IUserProfile | undefined
  setCurrentUser: Dispatch<SetStateAction<IUserProfile | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: getAuthFromLocalStorage(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<IAuth | undefined>(getAuthFromLocalStorage())
  const [currentUser, setCurrentUser] = useState<IUserProfile | undefined>()

  const saveAuth = (auth: IAuth | undefined) => {
    setAuth(auth)

    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    if (auth) {
      await logoutService()
        .then((res) => {
          if (res.data.code === 200 && res.data.message === 'Success') {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
        .catch((err) => {
          if (err?.response && err?.response?.status === 401) {
            saveAuth(undefined)
            setCurrentUser(undefined)
          }
        })
    }
  }

  return <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>{children}</AuthContext.Provider>
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserProfile()
          if (user) {
            setCurrentUser(user.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [auth])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuth}
