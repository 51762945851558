import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {toAbsoluteUrl} from '@/_metronic/helpers'
import {loginService} from '@/services/auth'
import {ILoginForm} from '@/types/auth'
import {AuthResolver} from '@/validations/loginValidation'

import {useAuth} from '../core/Auth'

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorRes, setErrorRes] = useState<{status: number; msg: string}>({status: 0, msg: ''})
  const {saveAuth} = useAuth()

  const {
    handleSubmit,
    register,
    formState: {errors, isValid, isSubmitting},
  } = useForm<ILoginForm>({
    resolver: AuthResolver,
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data: ILoginForm) => {
    setLoading(true)

    try {
      const {data: auth} = await loginService(data)
      saveAuth(auth.data)
      toast.success(intl.formatMessage({id: 'AUTH.SIGN_IN_SUCCESS'}))
    } catch (error: any) {
      const {response} = error
      if (response.data.code === 401) {
        setErrorRes({
          status: response.data.code,
          msg: intl.formatMessage({id: 'AUTH.LOGIN_FAILED_MESSAGE'}),
        })
      }
      saveAuth(undefined)
      setLoading(false)
      toast.error(intl.formatMessage({id: 'AUTH.SIGN_IN_FAILED'}))
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='kt_login_signin_form'>
      <div className='text-center mb-11'>
        <h2 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h2>
      </div>

      {!!(errorRes.status && errorRes.msg) && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorRes.msg}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-4 fw-bolder text-dark'>{intl.formatMessage({id: 'AUTH.EMAIL'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.ENTER_EMAIL'})}
          {...register('email')}
          className={['form-control bg-transparent', errors.email && 'is-invalid', isSubmitting && isValid && 'is-valid']
            .filter(Boolean)
            .join(' ')}
          autoComplete='off'
        />

        {errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.email.message}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-4 mb-0'>{intl.formatMessage({id: 'AUTH.PASSWORD'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.ENTER_PASSWORD'})}
          type='password'
          autoComplete='off'
          {...register('password')}
          className={['form-control bg-transparent', errors.password && 'is-invalid', isSubmitting && isValid && 'is-valid']
            .filter(Boolean)
            .join(' ')}
        />
        {errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.password.message}</span>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={isSubmitting}>
          {!isSubmitting && <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}</span>}
          {isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE_WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
