import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email'),
  password: Yup.string().required('Password is required'),
})

const sendEmailSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email'),
})

const verifyOTPSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Invalid email'),
  otp: Yup.string().required('OTP is required'),
})

const resetPasswordSchema = Yup.object().shape(
  {
    newPassword: Yup.string()
      .required('New password is required')
      .min(6, 'Password must be at least 6 characters long')
      .max(64, 'Password must be at the most 64 characters long')
      .matches(/\d/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain a uppercase letter')
      .matches(/[!@$%&*?]/, 'Password must contain a special character (!@$%&*?)'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .when('confirmPassword', {
        is: (value: string) => value?.length,
        then: (confirmPassword) => confirmPassword.oneOf([Yup.ref('newPassword')], 'Confirm password must be equal to new password'),
      }),
  },
  [['confirmPassword', 'confirmPassword']]
)

export const AuthResolver = yupResolver(loginSchema)
export const ForgotPasswordResolver = yupResolver(sendEmailSchema)
export const VerifyOTPResolver = yupResolver(verifyOTPSchema)
export const ResetPasswordResolver = yupResolver(resetPasswordSchema)
