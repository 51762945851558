import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {toAbsoluteUrl} from '@/_metronic/helpers'
import {useAuth} from '@/app/modules/auth'

// import DarkModeSwitcher from '../theme-mode/DarkModeSwitcher'
import {Languages} from './Languages'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const {currentUser, logout} = useAuth()

  const logoutHandler = async () => {
    setLoading(true)
    await logout()
    toast.success(intl.formatMessage({id: 'AUTH.SIGN_OUT_SUCCESS'}))
    setLoading(false)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Avatar profile'
              src={currentUser?.avatar ? currentUser?.avatar : toAbsoluteUrl('/media/avatars/blank.png')}
              className='rounded'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-3'>
              <span className='multiline-ellipsis'>{currentUser?.username}</span>
              <span className='badge badge-light-success fw-bolder fs-3 px-2 py-1 ms-2'>{currentUser?.role}</span>
            </div>
            <div className='fw-bold text-muted text-hover-primary fs-3'>{currentUser?.email}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2' />

      <div className='menu-item px-5'>
        <Link to={'/my-profile/overview'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MY_PROFILE'})}
        </Link>
      </div>

      <div className='separator my-2' />

      <Languages />

      {/* <DarkModeSwitcher /> */}

      <div className='menu-item px-5' data-kt-menu-trigger='click'>
        <div onClick={logoutHandler} className='menu-link px-5 d-flex justify-content-between'>
          {intl.formatMessage({id: 'AUTH.SIGN_OUT'})}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
