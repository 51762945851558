import qs from 'qs'
import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'

import {ID, QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&limit=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'limit'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})

  const filters = qs.stringify(state, {
    filter: ['keyword', 'role', 'isActive', 'status', 'type', 'field', 'fromDate', 'toDate', 'date', 'branchId', 'method', 'cardStatus'],
    skipNulls: true,
  })

  let queries =
    [pagination]
      .filter((f) => f)
      .join('&')
      .toLowerCase() +
    '&' +
    sort

  if (filters) queries = queries + '&' + filters

  return queries
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(id: ID, selected: Array<ID>, setSelected: Dispatch<SetStateAction<Array<ID>>>) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnDataSelect(id: ID, dataSelected: Array<any>, setDataSelected: Dispatch<SetStateAction<Array<any>>>, data: any) {
  if (!id) {
    return
  }

  const checkExistedData = dataSelected.find((item: any) => item?.id === id)

  if (checkExistedData) {
    setDataSelected(dataSelected.filter((item) => item?.id !== id))
  } else {
    const updatedSelected = [...dataSelected]
    const findItemData = data.find((item: any) => item?.id === id)
    updatedSelected.push(findItemData)
    setDataSelected(updatedSelected)
  }
}

function groupingOnSelectAll(
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: string[],
  setDataSelected?: Dispatch<SetStateAction<Array<any>>>,
  dataSelected?: any
) {
  if (!data) {
    return
  }

  if (!dataSelected) {
    return
  }

  if (data.length === 0) {
    setSelected([])
    return
  }

  if (dataSelected.length === 0) {
    setDataSelected && setDataSelected([])
    return
  }

  setSelected(data)
  setDataSelected && setDataSelected(dataSelected)
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  createResponseContext,
  groupingOnDataSelect,
  groupingOnSelect,
  groupingOnSelectAll,
  isNotEmpty,
  parseRequestQuery,
  stringifyRequestQuery,
  useDebounce,
}
