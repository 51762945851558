import {AxiosResponse} from 'axios'
import React, {useLayoutEffect, useState} from 'react'

import {useAuth} from '@/app/modules/auth/core/Auth'
import axiosInstance from '@/utils/axios'

type AxiosInterceptorProps = {
  children: JSX.Element
}

const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({children}) => {
  const [error, setError] = useState<any>(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  useLayoutEffect(() => {
    const interceptorResponse = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error: any) => {
        switch (error.response.status) {
          case 400: {
            // Handle BadRequest
            break
          }
          case 401: {
            // Handle Unauthorized

            saveAuth(undefined)
            setCurrentUser(undefined)
            break
          }
          case 403: {
            // Handle Forbidden
            setShowConfirmModal(true)
            break
          }
          case 429: {
            // Handle TooManyRequests
            break
          }
          default: {
            setError(error)
            break
          }
        }
        return Promise.reject(error)
      }
    )

    return () => axiosInstance.interceptors.response.eject(interceptorResponse)
  }, [])
  return <div>{children}</div>
}

export default AxiosInterceptor
