import React from 'react'
import {useIntl} from 'react-intl'

import {ThemeModeComponent} from '@/_metronic/assets/ts/layout'
import {KTSVG} from '@/_metronic/helpers'

import {ThemeModeType, useThemeMode} from './ThemeModeProvider'

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const DarkModeSwitcher = () => {
  const intl = useIntl()
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  return (
    <div className='menu-item px-5' data-kt-menu-trigger='hover' data-kt-menu-placement='left-start' data-kt-menu-flip='bottom'>
      <div className='menu-link px-5'>
        <span className='menu-title position-relative'>{intl.formatMessage({id: 'DARK_MODE.TITLE'})}</span>
      </div>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={['menu-link px-3 py-2', menuMode === 'light' && 'active'].filter(Boolean).join(' ')}
            onClick={() => switchMode('light')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-5' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'DARK_MODE.LIGHT'})}</span>
          </a>
        </div>
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={['menu-link px-3 py-2', menuMode === 'dark' && 'active'].filter(Boolean).join(' ')}
            onClick={() => switchMode('dark')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-5' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'DARK_MODE.DARK'})}</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default DarkModeSwitcher
