import enMessages from './en.json'
import enAuth from './en/auth.json'
import enBanner from './en/banner.json'
import enContactFeedback from './en/contact-feedback.json'
import enContent from './en/contents.json'
import enMenu from './en/menu.json'
import enNavbar from './en/navbar.json'
import enPaymentChannel from './en/payment-channel.json'
import enProducts from './en/products.json'
import enOrder from './en/transactions.json'
import enUser from './en/users.json'
import viMessages from './vi.json'
import viAuth from './vi/auth.json'
import viBanner from './vi/banner.json'
import viContactFeedback from './vi/contact-feedback.json'
import viContent from './vi/contents.json'
import viMenu from './vi/menu.json'
import viNavbar from './vi/navbar.json'
import viPaymentChannel from './vi/payment-channel.json'
import viProducts from './vi/products.json'
import viOrder from './vi/transactions.json'
import viUser from './vi/users.json'

const en = {
  ...enMessages,
  ...enContent,
  ...enUser,
  ...enAuth,
  ...enNavbar,
  ...enProducts,
  ...enContactFeedback,
  ...enOrder,
  ...enMenu,
  ...enBanner,
  ...enPaymentChannel,
}
const vi = {
  ...viMessages,
  ...viContent,
  ...viUser,
  ...viAuth,
  ...viNavbar,
  ...viProducts,
  ...viContactFeedback,
  ...viOrder,
  ...viMenu,
  ...viBanner,
  ...viPaymentChannel,
}

export {en, vi}
