/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'

import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white-40 text-uppercase fs-3 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/users-management'
        title={intl.formatMessage({id: 'USER.USERS'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/communication/com014.svg'
      >
        <SidebarMenuItem to='/users-management/users' title={intl.formatMessage({id: 'LIST'})} hasBullet />
        <SidebarMenuItem to='/users-management/membership-card' title={intl.formatMessage({id: 'Membership card'})} hasBullet />
        <SidebarMenuItem to='/users-management/barcodes' title={intl.formatMessage({id: 'Barcode card'})} hasBullet />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/products-management/products'
        activeSecondUrl='/products-management/create-product'
        icon='/media/icons/duotune/ecommerce/ecm009.svg'
        title={intl.formatMessage({id: 'PRODUCT.PRODUCT_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub
        to='/transactions-management'
        title={intl.formatMessage({id: 'TRANSACTION.TRANSACTION_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/ecommerce/ecm012.svg'
      >
        <SidebarMenuItem to='/transactions-management/orders' title={intl.formatMessage({id: 'TRANSACTION.ORDERS'})} hasBullet />
        <SidebarMenuItem to='/transactions-management/deposits' title={intl.formatMessage({id: 'TRANSACTION.DEPOSIT'})} hasBullet />
        <SidebarMenuItem
          to='/transactions-management/vending-machine'
          title={intl.formatMessage({id: 'TRANSACTION.VENDING_MACHINE'})}
          hasBullet
        />
        <SidebarMenuItem to='/transactions-management/vexere' title={intl.formatMessage({id: 'TRANSACTION.VEXERE'})} hasBullet />
        <SidebarMenuItem
          to='/transactions-management/point-transfer'
          title={intl.formatMessage({id: 'TRANSACTION.POINT_TRANSFER'})}
          hasBullet
        />
        <SidebarMenuItem to='/transactions-management/fixed-price' title={intl.formatMessage({id: 'Fixed price transaction'})} hasBullet />
        <SidebarMenuItem to='/transactions-management/posapp' title={intl.formatMessage({id: 'POSAPP transaction'})} hasBullet />
        <SidebarMenuItem to='/transactions-management/barcodes' title={intl.formatMessage({id: 'Barcode transaction'})} hasBullet />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/payment-channels'
        title={intl.formatMessage({id: 'Payment Channel Management'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/graphs/gra008.svg'
      >
        <SidebarMenuItem to='/payment-channels/list' title={intl.formatMessage({id: 'LIST'})} hasBullet />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/menu-management'
        title={intl.formatMessage({id: 'MENU.MENU_MANAGEMENT'})}
        fontIcon='bi-layers'
        icon='/media/icons/duotune/text/txt009.svg'
      >
        <SidebarMenuItem
          to='/menu-management/branch'
          activeSecondUrl='/menu-management/branch'
          title={intl.formatMessage({id: 'MENU.BRANCH'})}
          hasBullet
        />
        <SidebarMenuItem
          to='/menu-management/daily-menu'
          activeSecondUrl='/menu-management/create-menu'
          title={intl.formatMessage({id: 'MENU.DAILY_MENU'})}
          hasBullet
        />
        <SidebarMenuItem
          to='/menu-management/daily-products'
          activeSecondUrl='/menu-management/create-product-menu'
          title={intl.formatMessage({id: 'MENU.EXTRA_PRODUCTS'})}
          hasBullet
        />
        <SidebarMenuItem
          to='/menu-management/statistics'
          activeSecondUrl='/menu-management/statistics'
          title={intl.formatMessage({id: 'MENU.STATISTICS'})}
          hasBullet
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/banner-management/banners'
        activeSecondUrl='/banner-management/create-banner'
        icon='/media/icons/duotune/abstract/abs037.svg'
        title={intl.formatMessage({id: 'BANNER.ADS_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/contact-feedback/list'
        icon='/media/icons/duotune/communication/com012.svg'
        title={intl.formatMessage({id: 'CONTACT_FEEDBACK.TITLE'})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}

export {SidebarMenuMain}
