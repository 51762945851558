import clsx from 'clsx'
import {FC} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'

import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  activeSecondUrl?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({children, to, activeSecondUrl = '', title, icon, fontIcon, hasBullet = false}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to, activeSecondUrl)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot' />
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTSVG path={icon} className={['svg-icon-5', isActive ? 'text-primary' : 'text-gray-858585'].filter(Boolean).join(' ')} />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && <i className={clsx('bi fs-3', fontIcon)} />}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
